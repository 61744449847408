.about-container {
  display: flex;
  padding-top: 8%;
}

.parent-container {
  display: flex;
  flex-direction: column;
  padding: 0 20% 0;
}

.about-text-1 {
  color: #707070;
  font-size: 17px;
  font-family: "Rubik", sans-serif;
}

.about-text-2 {
  color: #f44e91;
  font-weight: bold;
  font-size: 20px;
  font-family: "Roboto", sans-serif;
}

.about-text-3 {
  color: #707070;
  font-family: "Rubik", sans-serif;
  font-size: 17px;
}

.about-text-4 {
  color: #707070;
  font-family: "Fira Sans", sans-serif;
  font-size: 18px;
}

.about-text-2,
.about-text-3,
.about-text-4 {
  margin-top: 25px;
}

.find-me-on-link-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.find-me-on-link:nth-child(1) img {
  height: 35px;
  width: 45px;
}

.find-me-on-link:nth-child(2) img {
  height: 50px;
  width: 50px;
}

.find-me-on-link:nth-child(3) img {
  height: 35px;
  width: 45px;
}

.find-me-on-link:nth-child(4) img {
  height: 35px;
  width: 35px;
}

.find-me-on-link:nth-child(5) img {
  height: 40px;
  width: 40px;
}

.c-t-div {
  display: flex;
  margin: 60px 0 100px;
}

.btn-contact {
  background-color: #344966;
  border-radius: 50px;
  padding: 15px 40px;
  color: #fff;
  margin-right: 30px;
  border: 1px solid transparent;
  cursor: pointer;
}

.btn-contact:hover {
  background-color: #fff;
  border: 1px solid #344966;
  color: #344966;
}

.resume-link {
  border: 1px solid #344966;
  border-radius: 50px;
  padding: 15px 30px;
  color: #344966;
}

.resume-link:hover {
  background-color: #344966;
  border: 1px solid transparent;
  color: #fff;
}

@media (max-width: 768px) {
  .parent-container {
    padding: 0 30px 60px;
  }

  .btn-contact {
    padding: 15px 25px !important;
  }

  .resume-link {
    padding: 15px 20px !important;
  }
}
