.contact-container {
  display: flex;
  justify-content: center;
  height: 100%;
  padding-top: 8%;
}

.hello-container {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.hello-wrapper {
  display: flex;
  flex-direction: column;
}

.hello-text-1 {
  font-weight: bold;
  font-size: 35px;
  margin-bottom: 30px;
}

.hello-text-2,
.hello-text-3 {
  font-size: 17px;
}

.mail-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
}

.span-or {
  padding-left: 100px;
  margin-top: 50px;
}

.span-mail-title {
  margin-top: 50px;
  margin-bottom: 5px;
}

.span-mail-info {
  color: #1c699b;
}

.form-container {
  width: 35%;
  display: flex;
  flex-direction: column;
}

.contact-title {
  font-family: "Oswald", sans-serif;
  font-size: 35px;
  margin-bottom: 30px;
}

form {
  display: flex;
  flex-direction: column;
}

.field-wrapper {
  background-color: #ebeef1;
  height: 50px;
  border-radius: 20px;
  margin-bottom: 20px;
  display: flex;
}

.fas,
.far {
  align-self: center;
  color: #9998a9;
  margin: 0 15px;
}

input {
  border: none;
  background-color: #ebeef1;
  width: 100%;
  outline: none;
  border-radius: 20px;
  padding: 5px;
  font-size: 16px;
}

.field-info {
  font-size: 13px;
  color: #344966;
}

.message-field-wrapper {
  background-color: #ebeef1;
  height: 150px;
  border-radius: 20px;
  margin-bottom: 40px;
}

textarea {
  height: 100%;
  width: 100%;
  background-color: #ebeef1;
  resize: none;
  border: none;
  border-radius: 20px;
  padding: 15px 10px;
  outline: none;
  font-family: Arial;
  font-size: 16px;
}

.btn-submit {
  background-color: #344966;
  border-radius: 50px;
  padding: 15px 0;
  color: #fff;
  border: 1px solid transparent;
  font-size: 16px;
  font-weight: bold;
  outline: none;
  cursor: pointer;
}

.btn-submit:hover {
  background-color: #fff;
  border: 1px solid #344966;
  color: #344966;
}

@media (max-width: 992px) {
  .contact-container {
    height: 100vh;
    flex-direction: column;
    overflow-y: auto;
    padding-top: 150px;
    padding-bottom: 150px;
  }

  .hello-container {
    width: 100%;
    align-items: center;
    margin-bottom: 60px;
  }

  .form-container {
    width: 100%;
    padding: 0 10%;
  }

  .hello-wrapper {
    width: 100%;
    align-items: center;
  }

  .mail-wrapper {
    width: 100%;
    align-items: center;
  }

  .span-mail-title {
    margin-top: auto !important;
  }

  .span-or {
    display: none;
  }
}
