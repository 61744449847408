ul li {
  user-select: none;
  list-style-type: none;
}

.header-ul {
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f0f0f0;
  height: 80px;
}

.header-list-item {
  margin-right: 60px;
  font-weight: bold;
  cursor: pointer;
  padding-bottom: 5px;
}

.hamburger-btn {
  display: none;
  margin-left: 10px;
}

.nav-links-container {
  flex: 1;
}

.hamburger-icon {
  color: #000 !important;
  font-size: 22px;
  cursor: pointer;
}

.nav-links {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.active-li {
  color: #f44e91;
  border-bottom: 3px solid #f44e91;
}

@media (max-width: 1080px) {
  .hamburger-btn {
    display: block !important;
  }

  .header-list-item {
    margin-right: 60px;
  }
}

@media (max-width: 768px) {
  .header-list-item {
    margin-right: 30px;
  }
}
