.portfolio-container {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
}

.top-container {
  justify-content: flex-end;
  padding: 10px;
  user-select: none;
}

.fa-times {
  font-size: 30px;
  color: #000;
  cursor: pointer;
}

.n-container {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio-picture {
  border-radius: 50%;
  height: 210px;
  width: 210px;
  object-fit: contain;
  margin-bottom: 20px;
}

.f-span {
  margin-top: 10px;
  font-family: "Pacifico", cursive;
  font-size: 1.4375rem;
  margin-bottom: 5px;
  /* color: #1c699b; */
  color: #344966;
}

.s-span {
  font-size: 17px;
  /* color: #1c699b; */
  margin-bottom: 60px;
}

.skills-wrapper {
  display: flex;
  justify-content: center;
}

.p-div {
  display: inline-flex;
  flex-direction: column;
  font-size: 14px;
  /* color: #1c699b; */
  width: max-content;

  > span {
    margin-bottom: 10px;
  }

  .fas {
    margin-right: 5px;
  }
}

.l-span {
  position: fixed;
  bottom: 10px;
  font-size: 14px;
  font-family: "Fira Sans Extra Condensed", sans-serif;
}

.link-owner {
  /* color: #1c699b; */
}
