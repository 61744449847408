@import './assets/styles/variables.scss';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::before,
::after {
  box-sizing: inherit;
}

body {
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.4;
  overflow: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

div {
  width: 100%;
}