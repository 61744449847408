.projects-container {
  display: flex;
  height: 100%;
}

.title-tab {
  width: 20%;
  display: flex;
  flex-direction: column;
  border-right: 1px solid #f3f3f3;
}

.single-title {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #f3f3f3;
  font-size: 1rem;
  opacity: 0.9;
  cursor: pointer;
  font-family: Arial;
}

.active-title {
  background: #344966;
  color: #fff;
  opacity: 1;
}

.details {
  width: 80%;
  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
}

.slideshow-container {
  width: 80%;
  height: 70vh;
  position: relative;
}

.all-slides {
  display: none;
  width: 100%;
  height: 100%;
}

.img-slide {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.details-info {
  padding-top: 10px;
  display: flex;
  flex-direction: column;
}

.details-description-wrapper {
  display: flex;
  margin-bottom: 7px;
  justify-content: center;
  font-size: 16px;
  align-items: center;
}

.details-title {
  font-weight: bold;
  margin-right: 5px;
  display: none;
}

.details-technologies {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 7px;
}

.details-technologies-heading {
  font-size: 16px;
}

.details-technologies-info {
  font-weight: bold;
  font-size: 15px;
  color: #1c699b;
  font-family: "Poppins", sans-serif;
}

/* Caption text */
.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* The dots/bullets/indicators */
.dot {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
}

.active {
  background-color: #717171;
}

/* Fading animation */
.fade {
  animation-name: fade;
  animation-duration: 3s;
}

@keyframes fade {
  from {
    opacity: 0.1;
  }
  to {
    opacity: 1;
  }
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -22px;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  background-color: rgba(0, 0, 0, 0.4);
  border: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.details-app-container {
  display: flex;
  justify-content: center;
}

.details-app-single {
  border-radius: 50px;
  border: 1px solid transparent;
  cursor: pointer;
  color: #fff;
  font-size: 16px;
  width: 100px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.details-app-live {
  background-color: #344966;
}

.details-app-live:hover {
  background-color: #fff;
  border: 1px solid #344966;
  color: #344966;
}

.details-app-github {
  background-color: #fff;
  border: 1px solid #344966;
  color: #344966;
  margin-left: 15px;
}

.details-app-github:hover {
  background-color: #344966;
  color: #ffffff;
}

.details-app-next {
  background-color: #fff;
  border: 1px solid #f44e91;
  color: #f44e91;
  width: 120px;
  margin-left: 15px;
  display: none;
}

.details-app-next:hover {
  background-color: #f44e91;
  color: #ffffff;
}

@media (max-width: 768px) {
  .title-tab {
    display: none;
  }

  .details {
    width: 100%;
    padding: 10px 0;
  }

  .slideshow-container {
    height: 60vh;
    width: 100%;
    padding: 0 20px;
  }

  .details-technologies {
    flex-direction: column;
  }

  .details-description-wrapper {
    flex-direction: column;
  }

  .details-app-next {
    display: block;
  }

  .details-title {
    display: block;
  }

  .details-description {
    text-align: center;
  }

  .next {
    right: 20px;
  }
}
