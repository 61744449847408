.container {
  display: flex;
}

.sidebar {
  display: flex;
  width: 23%;
  min-width: 23%;
}

.fixed-sidebar {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 95%;
  z-index: 10;
}

.hidden-sidebar {
  display: none;
}

@media (max-width: 768px) {
  .sidebar {
  }
}

@media (max-width: 1080px) {
  .sidebar {
    display: none;
  }
}
